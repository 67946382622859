'use client'

import { Box } from '@mui/material'
import { ReactNode } from 'react'
import ApolloProviderWrapper from '~/libs/apollo'

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <ApolloProviderWrapper>
      <Box
        width='100%'
        minHeight='100vh'
        sx={{
          backgroundImage: `url(/bg.png)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Box
          width='100%'
          minHeight='100vh'
          sx={{
            backgroundColor: 'rgba(0,0,0,.8)',
          }}
        >
          {children}
        </Box>
      </Box>
    </ApolloProviderWrapper>
  )
}

export default Layout
